<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
          <div class="p-5">
            

            <b-row>
              <b-col cols="12" md="6">
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-4"
                  label="Name"
                  v-model="itemModel.DocumentID"
                  :options="DocumentTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('DocumentType')"
                />
              </b-col>
              <b-col cols="12" md="6"
                v-if="itemModel.DocumentID == 1"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="itemModel.ScanTypeID"
                  :options="ScanTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('ScanType')"
                />
              </b-col>
              <b-col cols="12" md="6"
                v-if="itemModel.DocumentID == 2"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="itemModel.AnalysisTypeID"
                  :options="AnalysisTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('AnalysisType')"
                />
              </b-col>
              <b-col cols="12" md="6"
                v-if="itemModel.DocumentID == 3"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="itemModel.ReportTypeID"
                  :options="ReportTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('ReportType')"
                />
              </b-col>
              <b-col>
                <vs-input
                  class="w-full"
                  v-model="itemModel.Description"
                  :placeholder="$t('Description')"
                />
              </b-col>
            </b-row>

            <div style="text-align: center">
              <b-button
                style="width: 60%; margin-top: 2rem"
                @click="$emit('AddItem')"
                variant="primary"
                >{{ $t("Save") }}</b-button
              >
            </div>
          </div>
</template> 

<script>
import {
BBadge, BButton, BCard, BCardBody, BCardText, BCardTitle, BCol, BForm, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BFormInput, BFormSpinbutton, BImg, BLink, BRow, BTable
} from 'bootstrap-vue';
export default {
  data() {
    return {};
  },
  props: {
    itemModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {BBadge, BButton, BCard, BCardBody, BCardText, BCardTitle, BCol, BForm, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BFormInput, BFormSpinbutton, BImg, BLink, BRow, BTable},
  methods: {},
  computed: {
    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
    DoctorTypes() {
      return this.$store.state.DoctorRequestTypeList.DoctorRequestTypes;
    },
  },
  created() {
    debugger
   
    // this.$store.dispatch("DoctorRequestTypeList/GetAllDoctorRequestTypes");
  },
};
</script>

<style lang="scss">
.MyForm {
  width: 50vh;
  margin: auto;
}
</style>
