<template>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="list-view product-checkout" @submit.prevent="handleSubmit(onSubmit)">
            <div>

                <b-row>
                    <b-col>
                        <!-- <b-row>
              <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="cursor: pointer">
              </feather-icon>
              <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="cursor: pointer">
              </feather-icon>
              <p class="text-xl">
                {{ $t("SurgeryTreatment") }}
              </p>
            </b-row> -->
                    </b-col>
                    <!-- <h3 class="mb-5 custHeadSurgery">
                        {{ $t("BookingSurgeryTitle") }}
                    </h3> -->
                    <b-col>

                    </b-col>

                </b-row>

                <b-row cols="12" md="3">
                    <b-col cols="12" md="">
                        <b-card>
                            <b-row>
                                <b-col cols="12" md="4">
                                    <h1 class="colorHead text-4xl mt-0 px-2">
                                        <p>{{ Model.Surgery.Name }}</p>
                                    </h1>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <star-rating :max-rating="5" :star-size="30" :read-only="true"
                                        v-model="Model.Hospital.Rate"></star-rating>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <imageLazy :imageStyle="{
                                        'border-radius': '15px',
                                        height: '200px',
                                        width: '14vw',
                                        'object-fit': 'cover',
                                    }" :imageClass="'doctorImage'" :src="baseURL + Model.Hospital.ImagePath"
                                        placeHolderType="hospital" />
                                </b-col>

                                <b-col cols="12" md="9">
                                    <h6 class="HospitalInfo lg:w-2/3 w-full">
                                        <p v-html="Model.Hospital.HospitalInfo"></p>
                                    </h6>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <div class="mt-4" v-if="!$acl.check('patient') && !$acl.check('notAuthenticated')">
                                        <h6>
                                            {{ Model.Hospital.Email }}
                                        </h6>
                                        <h6 class="mt-1">
                                            {{ Model.Hospital.Phone }}
                                        </h6>
                                    </div>

                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col v-if="
                                Model.HospitalSurgeryIncludedItems &&
                                Model.HospitalSurgeryIncludedItems.length > 0">
                                    <div>
                                        <h3 class="greenColor">
                                            {{ $t("SurgeryTreatmentIncludes") }}
                                        </h3>
                                    </div>

                                    <b-row v-for="item in Model.HospitalSurgeryIncludedItems" :key="item.ID">
                                        <p class="circle primaryBackColor mt-0 ml-1 mr-2"></p>
                                        <h5 style="width: 90%" v-if="item.SurgeryItem">
                                            <p v-if="item.SurgeryItem.Name != null">{{ item.SurgeryItem.Name }}</p>
                                        </h5>
                                    </b-row>

                                </b-col>

                                <b-col v-if="
                                    Model.HospitalSurgeryExcludedItems &&
                                    Model.HospitalSurgeryExcludedItems.length > 0
                                ">
                                    <div>
                                        <h3>
                                            <p class="greenColor">
                                                {{ $t("SurgeryTreatmentNotIncludes") }}
                                            </p>
                                        </h3>
                                    </div>
                                    <b-row v-for="item in Model.HospitalSurgeryExcludedItems" :key="item.ID">
                                        <p class="circle primaryBackColor  mt-0 ml-1 mr-2"></p>
                                        <h5 style="width: 90%" v-if="item.SurgeryItem">
                                            <p v-if="item.SurgeryItem.Name != null">{{ item.SurgeryItem.Name }}</p>
                                        </h5>

                                    </b-row>

                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col class=" lg:w-2/3 w-full mt-10" v-if="
                                    !$acl.check('hospital') &&
                                    !$acl.check('hospitalgroup') &&
                                    !IsDoctorRole
                                ">
                                    <h3 class="mr-4 ml-4 greenColor">{{ $t("Paymentconditions") }} :</h3>
                                    <p class="w-full" style="color: #2753D9"></p>
                                    <h5 class="mr-8 ml-8 lg:w-2/3" style="color: #2753D9">
                                        <label style="font-size: 1.5rem">.</label>
                                        {{ $t("PaymentconditionDescriptionFirst") }}<br />
                                        <label style="font-size: 1.5rem">.</label>
                                        {{ $t("PaymentconditionDescriptionSecound") }}<br />
                                        <label style="font-size: 1.5rem">.</label>
                                    </h5>

                                </b-col>
                                <b-col class=" lg:w-2/3 w-full mt-10" v-if="
                                !$acl.check('hospital') &&
                                !$acl.check('hospitalgroup') &&
                                !IsDoctorRole">
                                    <h3 class="mr-4 ml-4 greenColor">
                                        {{ $t("Cancellationconditions") }}:
                                    </h3>
                                    <p class="w-full" style="color: #2753D9" v-if="
                                        !$acl.check('hospital') &&
                                        !$acl.check('hospitalgroup') &&
                                        !IsDoctorRole
                                    "></p>
                                    <h5 class="mr-8 ml-8 lg:w-2/3" style="color: #2753D9">
                                        <label style="font-size: 1.5rem">.</label>

                                        {{ $t("CancellationconditionDescriptionFirst") }}<br />
                                        <label style="font-size: 1.5rem">.</label>

                                        {{ $t("CancellationconditionDescriptionSecond") }}<br />
                                        <label style="font-size: 1.5rem">.</label>

                                        {{ $t("CancellationconditionDescriptionThird") }}<br />
                                        <label style="font-size: 1.5rem">.</label>

                                        {{ $t("CancellationconditionDescriptionFourth") }}<br />

                                    </h5>
                                </b-col>
                            </b-row>

                        </b-card>
                    </b-col>
                    <b-col cols="12" md="3">

                        <b-card>
                            <b-col class="mt-0">
                                <vs-button v-if="!IsDoctorRole"
                                    class="w-full mt-2 customizer-btn text-sm bg-white text-primary">
                                    <p :style="[
                                        Model.PriceAfterDiscount
                                            ? { 'text-decoration': 'line-through' }
                                            : {},
                                    ]"></p>
                                    <p class="sizeCust" :style="[
                                        Model.PriceAfterDiscount &&
                                            Model.PriceAfterDiscount != Model.DefaultHospitalPriceInUSD
                                            ? { 'text-decoration': 'line-through' }
                                            : {},
                                    ]">
                                        <span style="font-weight: bold">
                                            {{ $t("PriceStartingFrom") }}
                                            <span v-if="Model.DefaultHospitalPriceInUSD > 0">{{
                                                    Model.DefaultHospitalPriceInUSD.toFixed(2).replace(
                                                        /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                        "$1,"
                                                    )
                                            }}</span>
                                            {{ $t("$") }}
                                        </span>
                                    </p>
                                </vs-button>
                                <vs-button v-if="
                                    Model.PriceAfterDiscount &&
                                    Model.PriceAfterDiscount != Model.DefaultHospitalPriceInUSD &&
                                    !IsDoctorRole
                                " class="w-full mt-2 customizer-btn text-sm bg-white text-primary"
                                    style="font-weight: bold">
                                    {{ $t("PriceStartingFrom") }}
                                    <span v-if="Model.PriceAfterDiscount > 0">
                                        {{
                                                Model.PriceAfterDiscount.toFixed(2).replace(
                                                    /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                    "$1,"
                                                )
                                        }}
                                    </span>
                                    {{ $t("$") }}
                                </vs-button>
                                <vs-button v-if="showAddRequest && Model.StatusID != 3 && Model.StatusID != 5"
                                    class="w-full mt-2 customizer-btn text-sm text-white bg-primary"
                                    @click="ShowAddSurgeryItemPopup = true">{{ $t("RequiredDocs") }}
                                </vs-button>
                                <vs-button v-if="showAddRequest && Model.StatusID != 3 && Model.StatusID != 5"
                                    class="w-full mt-2 customizer-btn text-sm text-white bg-primary"
                                    @click="showDownloadReports()">{{
                                            $t("PatientMedicalReportAndRequesteds")
                                    }}
                                </vs-button>

                                <vs-button v-if="
                                !$acl.check('hospital') &&
                                !$acl.check('hospitalgroup') &&
                                !IsDoctorRole" @click="$emit('next-step',Model,surgeryModel)">
                                    {{ $t("BookTheSurgery") }}
                                </vs-button>

                                <!-- <vs-button v-if="
                  !$acl.check('hospital') &&
                  !$acl.check('hospitalgroup') &&
                  !IsDoctorRole
                " @click="BookTheSurgery()" class="sizeCust w-full mt-2 customizer-btn text-sm text-white bg-primary"
                            @click="$emit('next-step',Session,doctor)"
>
                  {{ $t("BookTheSurgery") }}
                </vs-button> -->
                                <vs-button v-if="
                                    $acl.check('hospital') &&
                                    $route.params.ViewTypeID &&
                                    $route.params.ViewTypeID == 1
                                " @click="EditHospitalSurgery(Model.ID)"
                                    class="w-full mt-2 customizer-btn text-sm text-white bg-primary">{{
                                            $t("EditTreatment")
                                    }}
                                </vs-button>


                                <p class="p-1 text-sm" v-if="
                                !$acl.check('hospital') &&
                                !$acl.check('hospitalgroup') &&
                                !IsDoctorRole">
                                    {{ $t("selectSpacificDoctor") }}
                                    <a :href="'mailto:patient.service@doclinia.com?subject=book for surgery ' + Model.Surgery.Name
                                    ">patient.service@dawyini.com</a>
                                </p>

                            </b-col>

                        </b-card>
                       
                    </b-col>
                </b-row>
                <vs-popup title="" :active.sync="ShowAddSurgeryItemPopup">
      <AddSurgeryItemPopup
        :itemModel="addItemModel"
        v-if="ShowAddSurgeryItemPopup"
        @closePop="ShowAddSurgeryItemPopup = false"
        @AddItem="AddPatientMedicalReportRequest()"
      ></AddSurgeryItemPopup>
    </vs-popup>

    <vs-popup class="w-full" title="" :active.sync="showDownloadReportsfield">
      <showDownloadReportsTreatmentsCom
        :patientDoc="patientDoc"
        v-if="showDownloadReportsfield"
        @closePop="showDownloadReportsfield = false"
      ></showDownloadReportsTreatmentsCom>
    </vs-popup>
            </div>

        </b-form>

    </validation-observer>

</template>

<script>
import imageLazy from "@/components/image_lazy.vue";
import point from "@/components/point.vue";
import { domain } from "@/gloabelConstant.js";
import AddSurgeryItemPopup from "@/views/Patient/PatientSurgeries&Treatments/AddSurgeryDocumentPopup.vue";
import showDownloadReportsTreatmentsCom from "@/views/Patient/PatientSurgeries&Treatments/PatientMedicalReportPopup.vue";
import {
BButton,
BCard,
BCardBody,
BCardHeader,
BCardText,
BCardTitle,
BCol,
BForm,
BFormGroup,
BFormInput,
BFormInvalidFeedback,
BImg,
BRow
} from "bootstrap-vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";


export default {
    components: {
        AddSurgeryItemPopup,showDownloadReportsTreatmentsCom,
        imageLazy,
        BImg,
        BForm,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,
        StarRating,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        point,
    },
    props: {
        Model: {
            type: Object,
            default: () => { },
        },
         patientModel:{
            type: Object,
            default: () => { },
        },
        surgeryModel:{
            type: Object,
            default: () => { },
        },
        showAddRequest:{
            type: Boolean,
            default: () => false,
        }
    },
    data() {
        return {
            baseURL: domain,
            IsDoctorRole: false,
            ShowAddSurgeryItemPopup: false,
            addItemModel: {},
            patientDoc: {},
      showDownloadReportsfield: false,


        };
    },
    methods: {
        EditHospitalSurgery(HospitalSurgeryID) {
      this.$router.push(
        "/hospital/Add-EditTreatmentSurgery/" + HospitalSurgeryID
      );
    },
        showDownloadReports() {
      debugger;
      this.patientDoc.PatientReservationSurgeryID =
        this.$route.params.PatientReservationSurgeryID;

      this.showDownloadReportsfield = true;
      debugger;
    },
        changeFormatedDate(date) {
            return moment(date).format("LL");
        },
        AddPatientMedicalReportRequest() {
      debugger;
      var PatientRequestModel = {};
      PatientRequestModel.PatientReservationSurgeryID =
        this.$route.params.PatientReservationSurgeryID;
      PatientRequestModel.DocumentID = this.addItemModel.DocumentID;
      PatientRequestModel.AnalysisTypeID = this.addItemModel.AnalysisTypeID;
      PatientRequestModel.ReportTypeID = this.addItemModel.ReportTypeID;
      PatientRequestModel.ScanTypeID = this.addItemModel.ScanTypeID;
      PatientRequestModel.Description = this.addItemModel.Description;
      this.addItemModel = {};
      PatientRequestModel.StausID = 1;
      this.$vs.loading();
      this.$store
        .dispatch(
          "patientList/AddPatientMedicalReportRequest",
          PatientRequestModel
        )
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
            window.showSuccess();
          }

          this.$vs.loading.close();
        });
      this.ShowAddSurgeryItemPopup = false;
    },
    },
    created(){
    this.IsDoctorRole = this.$acl.check("doctor");

    }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
