<template>
  <div id="PMReportDownload" class="items-center justify-center inset-0 ...">
    <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="Model"
          >
            <template slot="thead">
              <vs-th >{{ $t("MedicalDocumentation") }}</vs-th>
              <vs-th > {{ $t("Date") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    {{ tr.ReportTypeName }}
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium truncate" type="date">
            {{ tr.Date.split("T")[0] }}
          </p>
                  </vs-td>
                  <vs-td>
                    <div v-if="tr.AttachPath" class="m-3">
            <a :href="baseURL + tr.AttachPath" download target="_blank"  @click="changeDocStatus(tr)"
>
              <vs-avatar
                color="primary"
                icon-pack="feather"
                icon="icon-download"
                style="background: primary"
                class="-m-3"
            /></a>
            <p
              style="
                color: #ffffff;
                font-size: 12px;
                opacity: 1;

                line-height: 30px;
              "
            >
              {{ $t("Download") }}
            </p>
          </div>
          <div v-else>
            <p class="product-name font-medium truncate" type="date">
            {{ $t("Pending") }}
          </p>
          </div>
                  </vs-td>
                
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>

  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
    };
  },
  props: {
    patientDoc: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        debugger;
        return this.$refs.table.currentx;
      }
      return 0;
    },
    doctorMedical() {
      return this.$store.state.DoctorList.patientReservation;
    },
  },
  methods: {
      changeDocStatus(Doc) {
      debugger
      Doc;
      debugger
      var model = {};
      model.doctorID = Doc.DoctorID;
      model.patientID = Doc.PatientID;
      model.PatientReservationSurgeryID = Doc.PatientReservationSurgeryID;

      model.ID = Doc.ID;
      model.DocumentTypeID = Doc.DocumentTypeID;

      debugger;
      this.$store
        .dispatch("DoctorList/ChangeDocStatus", model)
        .then((res) => {
          if (res.status == 200) {
            
            debugger;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.deleteData,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    }, 
    searchData() {
      //mkl
      debugger;
      this.$vs.loading();
      var model = {};
      model.PatientReservationSurgeryID = this.patientDoc.PatientReservationSurgeryID;

      this.$store
        .dispatch("DoctorList/GetAllPatientMedicalReportDownloadHospitalSurgery", model)
        .then((res) => {
          this.$vs.loading.close();
          if (res.status == 200) {
            debugger;
            this.Model = res.data.Data;
            debugger;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    debugger;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    debugger;
    this.searchData();
  },
};
</script>

<style lang="scss">

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

</style>
