<template>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="list-view product-checkout" @submit.prevent="handleSubmit(onSubmit)">

            <b-row class="mt-1 px-2">
                <h1 class="colorHead text-4xl mt-0 px-2">
                    <p> {{ "your reservation surgery name is: " }}{{ Model.Surgery.Name }}</p>
                </h1>
            </b-row>
            <b-row>
                <b-col>
                    <imageLazy :imageStyle="{
                        'border-radius': '15px',
                        height: '250px',
                        width: '18vw',
                        'object-fit': 'cover',
                    }" :imageClass="'doctorImage'" :src="baseURL + Model.Hospital.ImagePath"
                        placeHolderType="hospital" class="mx-2"/>
                </b-col>

                <b-col>

                    <div>
                        <b-row>
                            <div class="mb-0 mt-1 px-2">
                                <h6 class="bg-white mt-7 text-black" style="margin-left: 10px">
                                    {{ $t("From") }}:
                                    <span style="color: #aba9a4" v-if="Model.DateFromFormated">
                                        {{ Model.DateFromFormated }}</span>
                                    <span style="color: #aba9a4" v-else>
                                        {{ $t("ChoiseDate") }}</span>
                                    {{ $t("To") }}:
                                    <span style="color: #aba9a4" v-if="Model.DateToFormated">
                                        {{ Model.DateToFormated }}</span>
                                    <span style="color: #aba9a4" v-else>
                                        {{ $t("ChoiseDate") }}</span>
                                </h6>
                            </div>

                        </b-row>

                        <div class="pl-9 pr-9 mt-7 px-1">
                            <v-date-picker v-model="Model.range" multiple="true" is-range
                                :locale="$vs.rtl ? 'ar' : 'en'" @change="changeDateFormat" :min-date="addDays(4)"
                                @dayclick="changeDateFormat" />
                        </div>
                    </div>
                </b-col>

                <b-col>
                    <div>
                        <label class="w-full pl-12 pr-12 mt-3">{{ $t("MedicalDocumentsNeeded") }} :</label>
                        <b-row class="mt-5 m-5" v-for="doc in Model.HospitalSurgeryRequiredDocuments" :key="doc.ID">
                            <b-col>
                                <p class="circle primaryBackColor mt-4"></p>

                                <h6 class="ml-1 mr-1 mt-2">
                                    <p style="font-weight: bold">
                                        {{ doc.DocumentTypeName }}-{{ doc.Description }}
                                    </p>
                                </h6>
                            </b-col>

                            <b-col>
                                <input type="file" class="hidden" :id="'uploadImgInput' + doc.ID"
                                    :ref="'uploadImgInput' + doc.ID" accept="image/*" @change="AttachmentUploaded"
                                    @on-success="successUpload" />

                                <feather-icon @click="upload(doc.ID, doc)" icon="UploadIcon" style="width: 20px">
                                </feather-icon>

                                <vs-checkbox disabled v-model="doc.IsUploaded"></vs-checkbox>
                                <feather-icon v-if="doc.IsUploaded" icon="TrashIcon" @click.stop="RemoveDocuments(doc)"
                                    class="cursor-pointer mt-2"></feather-icon>
                            </b-col>

                        </b-row>

                    </div>
                </b-col>

            </b-row>
            <b-row>
                <div class="px-3 p-1">
                    <vs-button class="w-full sm:w-auto text-center mt-2" color="primary" :disabled="!canProceed"
                        @click="Proceed">
                        {{ $t("Proceed") }}
                    </vs-button>
                </div>

            </b-row>
        </b-form>
    </validation-observer>

</template>

<script>
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";


import {
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BImg,
    BRow,
    
} from "bootstrap-vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";

export default {
    components: {
        // BSV
        BImg,
        BForm,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        imageLazy,
    },
    props: {
        Model: {
            type: Object,
            default: () => { },

        },
        // patientModel: {
        //     type: Object,
        //     default: () => { },
        // },
        //  surgeryModel:{
        // type: Object,
        //      default: () => { },
        //  },


    },
    data() {
        return {
            baseURL: domain,
            updatedPatientSession: {},
            surgeryModel: {},
            patientModel: {},
            IsDoctorRole: false,

        };
    },
    methods: {
        changeFormatedDate(date) {
            return moment(date).format("LL");
        },
        changeDateFormat() {
            this.Model.DateFromFormated = moment(this.Model.range.start).format("LL");
            this.Model.DateToFormated = moment(this.Model.range.end).format("LL");
        },
        addDays(days) {
            var date = new Date();
            date.setDate(date.getDate() + days);
            return date;
        },

        AttachmentUploaded(input) {
            if (input.target.files && input.target.files[0]) {
                let data = new FormData();
                data.append("file", input.target.files[0]);
                this.documentModel = {};
                axios.post(this.uploadUrl, data).then((response) => {
                    this.documentModel.AttachPath = response.data;
                    this.successUpload(this.Item);
                });
            }
        },
        successUpload(item) {

            item.IsUploaded = true;
            item.AttachPath = this.documentModel.AttachPath;
            this.documentModel.PatientMedicalHistoryID =
                this.patientModel.PatientMedicalHistory.ID;
            this.documentModel.PatientID = this.patientModel.ID;
            this.documentModel.ReportTypeID = item.ReportTypeID;
            this.documentModel.AnalysisTypeID = item.AnalysisTypeID;
            this.documentModel.ScanTypeID = item.ScanTypeID;
            this.documentModel.Description =
                this.Model.Surgery.Name +
                "-" +
                item.DocumentTypeName +
                "-" +
                item.Description;
            this.documentModel.DocumentTypeID = item.DocumentTypeID;
            this.documentModel.HospitalSurgeryRequiredDocumentID = item.ID;
            if (this.documentModel.ScanTypeID > 0) {
                //scan type
                this.PatientMedicalHistoryScans.push(this.documentModel);
            } else if (this.documentModel.AnalysisTypeID > 0) {
                //analysis type
                this.PatientMedicalHistoryAnalysis.push(this.documentModel);
            } else {
                //report type
                this.PatientMedicalHistoryRepors.push(this.documentModel);
            }
        },
        RemoveDocuments(documentModel) {
            if (documentModel.ScanTypeID > 0) {
                //scan type
                const ItemIndex = this.PatientMedicalHistoryScans.findIndex(
                    (p) => p.AttachPath == documentModel.AttachPath
                );
                this.PatientMedicalHistoryScans.splice(ItemIndex, 1);
            } else if (documentModel.AnalysisTypeID > 0) {
                //analysis type
                const ItemIndex = this.PatientMedicalHistoryAnalysis.findIndex(
                    (p) => p.AttachPath == documentModel.AttachPath
                );
                this.PatientMedicalHistoryAnalysis.splice(ItemIndex, 1);
            } else {
                //report type
                const ItemIndex = this.PatientMedicalHistoryRepors.findIndex(
                    (p) => p.AttachPath == documentModel.AttachPath
                );
                this.PatientMedicalHistoryRepors.splice(ItemIndex, 1);
            }
            documentModel.IsUploaded = false;
        },
        upload(id, doc) {
            this.Item = doc;
            var el = document.getElementById("uploadImgInput" + id);
            el.click();
        },

        Proceed() {
            debugger;
            this.surgeryModel.ID = 0;
            this.surgeryModel.PatientID = this.patientModel.ID;
            if (this.surgeryModel.PatientID == undefined) {
                this.surgeryModel.PatientID =
                    this.$store.state.AppActiveUser.Patient.ID;
            }
            this.surgeryModel.HospitalSurgeryID = this.Model.ID;
            this.surgeryModel.ProcessingFees = this.Model.ProcessingFees;
            this.surgeryModel.VAT = this.Model.VAT;
            this.surgeryModel.PaymentStatusID = 1;
            this.surgeryModel.SurgeryDateFrom = this.Model.range.start;
            this.surgeryModel.SurgeryDateTo = this.Model.range.end;
            this.surgeryModel.StatusID = 1;
            this.surgeryModel.PatientMedicalHistoryScans =
                this.PatientMedicalHistoryScans;
            this.surgeryModel.PatientMedicalHistoryAnalysis =
                this.PatientMedicalHistoryAnalysis;
            this.surgeryModel.PatientMedicalHistoryRepors =
                this.PatientMedicalHistoryRepors;
            this.$vs.loading();
            var vm = this;
            this.$store
                .dispatch("patientList/AddPatientReservationSurgery", this.surgeryModel)
                .then((res) => {
                    this.surgeryModel = res.data.Data;
                    window.showSuccess(res.data.message);

                    this.$vs.loading.close();
                    this.ShowBookTheSurgery1 = false;
                    this.ShowConfirmation = true;
                    this.GetSurgery();
                    this.isSelected = false;
                    debugger;
                    // this.surgeryModel=surgeryModel;
                    this.$emit('next-step', vm.Model, vm.surgeryModel);
                    //this.$emit("next-step", surgeryModel, Model, patientModel);
                })
                .catch((err) => {
                    this.$vs.loading.close();
                    window.showError(err.response.data.message);
                });
        },

        GetSurgery() {
            const ID = this.$route.params.ID;
            this.$vs.loading();
            this.$store
                .dispatch("patientList/GetHospitalSurgery", ID)
                .then((res) => {
                    if (res.status == 200) {
                        this.Model = res.data.Data;
                        this.Model.StatusID;
                        if (
                            this.Model.Hospital.Rate == null ||
                            this.Model.Hospital.Rate == undefined ||
                            this.Model.Hospital.Rate == "NaN"
                        ) {
                            this.Model.Hospital.Rate = 0;
                        }

                        if (
                            this.$acl.check("patient") &&
                            this.$route.params.ViewTypeID != undefined
                        ) {
                            this.surgeryModel.ID =
                                this.$route.params.PatientReservationSurgeryID;

                            if (this.$route.params.ViewTypeID == 14) {
                                this.surgeryModel = {};

                                this.$router.push({
                                    name: "BookingSummary",
                                    params: {
                                        Flag: 2,
                                        ID: this.$route.params.PatientReservationSurgeryID,
                                    },
                                });
                            } else this.Showpayment = true;
                        }
                    }

                    this.$vs.loading.close();
                })
                .catch(() => {
                    this.$vs.loading.close();
                });
        },


    },

    computed: {
        canProceed() {
            return this.Model.range;
        },

    },
};

</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
