<template>
  <div>
    <form-wizard ref="refFormWizard" color="#2753D9" :title="null" :subtitle="null" finish-button-text="Submit"
      back-button-text="Previous" hide-buttons class="checkout-form-wizard steps-trans">

      <tab-content  :title="$t('SurgeryTreatment')" icon="feather icon-user">
        <SurgeriesTreatmentsDetails :showAddRequest="showAddRequest" @next-step="nextTabBookingYourSurgery" :Model="Model"
          :patientModel="patientModel" :surgeryModel="surgeryModel" />
      </tab-content>

      <tab-content v-if="!showAddRequest&&!this.$acl.check('hospital')" :disabled="DisableTap1" :title="$t('BookingYourSurgery')" icon="feather icon-info">
        <ChooseYourSurgeryDate :Model="Model" :surgeryModel="surgeryModel" @next-step="nextTabDepositFees"
          @Previos-step="PreStep" />
      </tab-content>

      <tab-content v-if="!showAddRequest&&!this.$acl.check('hospital')" :title="$t('TwoSetSurgery')" icon="feather icon-home">
        <ConfirmSelectedSurgery :Model="Model" :surgeryModel="surgeryModel" />

      </tab-content>

      <!-- <tab-content :title="$t('Payment')" icon="feather icon-credit-card">
        <div>
          payment
        </div>
      </tab-content> -->

    </form-wizard>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>

  </div>
</template>

<script>
import axios from "@/axios.js";
import imageLazy from "@/components/image_lazy.vue";
import point from "@/components/point.vue";
import { domain, mainSetting } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
import AddSurgeryItemPopup from "@/views/Patient/PatientSurgeries&Treatments/AddSurgeryDocumentPopup.vue";
import ChooseYourSurgeryDate from '@/views/Patient/PatientSurgeries&Treatments/ChooseYourSurgeryDate.vue';
import ConfirmSelectedSurgery from '@/views/Patient/PatientSurgeries&Treatments/ConfirmSelectedSurgery.vue';
import showDownloadReportsTreatmentsCom from "@/views/Patient/PatientSurgeries&Treatments/PatientMedicalReportPopup.vue";
import SurgeriesTreatmentsDetails from '@/views/Patient/PatientSurgeries&Treatments/SurgeriesTreatmentsDetails.vue';
import UserNotLogin from "@/views/UserNotLogin.vue";
import { ref } from '@vue/composition-api';
import {
BBadge, BButton, BCard, BCardBody, BCardText, BCardTitle, BCol, BForm, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BFormInput, BFormSpinbutton, BImg, BLink, BRow, BTable
} from 'bootstrap-vue';
import moment from "moment";
import { FormWizard, TabContent } from 'vue-form-wizard';
import StarRating from "vue-star-rating";


export default {
  data() {
    return {
      Item: {},
      EnglishLan: false,
      ShowAddSurgeryItemPopup: false,
      Showpayment: false,
      showDownloadReportsfield: false,
      showAddRequest: false,
      ShowLoginPopup: false,
      ShowBookTheSurgery1: false,
      ShowBookingSurgeryConfirm: false,
      ShowDocumentUpload: false,
      baseURL: domain,
      IsDoctorRole: false,
      uploadUrl: domain + "api/Common/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      Model: {
        Hospital: {},
        Surgery: {},
        range: {},
        HospitalSurgeryDoctors: [],
        HospitalSurgeryIncludedItems: [],
        HospitalSurgeryExcludedItems: [],
        HospitalSurgeryRequiredDocuments: [],
      },
      content_visible: true,
      DisableTap1: false,
      DisableTap2: false,
      patientDoc: {},
      addItemModel: {},
      ListOfDocument: [],
      PatientMedicalHistoryScans: [],
      PatientMedicalHistoryRepors: [],
      PatientMedicalHistoryAnalysis: [],
      ShowConfirmation: false,
      passwordType: "password",
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      email: this.$route.params.email,
      password: "",
      code: null,
      password2: null,
      documentModel: {},
      patientModel: {},
      Uploaded: false,
      value1: "",
      surgeryModel: {},

    };
  },

  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    return {
      refFormWizard,
      formWizardNextStep,

    }
  },

  components: {
    AddSurgeryItemPopup,
    showDownloadReportsTreatmentsCom,
    StarRating,
    UserNotLogin,
    imageLazy,
    point,
    BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink
    , BCardText, BCol, BFormGroup, BRow, BTable, BCardTitle,
    BForm, BFormCheckbox, BFormCheckboxGroup,
    BFormInput,
    FormWizard,
    TabContent,
    SurgeriesTreatmentsDetails,
    ChooseYourSurgeryDate,
    ConfirmSelectedSurgery
  },
  computed: {
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },

    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },

    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {

    // nextTabDepositFees(Model,surgeryModel,patientModel) {
    //   debugger;
    //   this.surgeryModel = surgeryModel;
    //   this.Model=Model;
    //   this.patientModel=patientModel;
    //   this.refFormWizard.nextTab();
    //   this.DisableTap2 = true;
    // },
    nextTabDepositFees(Model,surgeryModel) {
      debugger;
      this.surgeryModel = surgeryModel;
      this.Model=Model;
      this.refFormWizard.nextTab();
      this.DisableTap2 = true;
    },
    nextTabBookingYourSurgery(model,surgeryModel) {
      debugger;
      if (!this.$store.state.auth.isUserLoggedIn()) {
        this.ShowLoginPopup = true;
        return false;
      } else {
        this.ShowBookTheSurgery1 = true;
      }
      this.Model = model;
      this.surgeryModel=surgeryModel;
      this.refFormWizard.nextTab()
      this.DisableTap1 = true;

    },

    PreStep() {
      
      this.refFormWizard.prevTab();
    },

    PayNow() {

      this.$vs.loading();
      this.surgeryModel.FrontUrl = window.location.origin;
      this.$store
        .dispatch("patientList/ProcessingPaymentDatail", this.surgeryModel)
        .then((res) => {
          window.location.href = res.data.Data.transaction.url;
          this.ShowConfirmation = false;
        });
    },

    upload(id, doc) {
      this.Item = doc;
      var el = document.getElementById("uploadImgInput" + id);
      el.click();
    },

    backConfirmation() {
      this.ShowConfirmation = false;
      window.showAddSuccess();
    },

    EditHospitalSurgery(HospitalSurgeryID) {
      this.$router.push(
        "/hospital/Add-EditTreatmentSurgery/" + HospitalSurgeryID
      );
    },
    back() {
      this.$router.go(-1);
    },
    BookTheSurgery() {
      if (!this.$store.state.auth.isUserLoggedIn()) {
        this.ShowLoginPopup = true;
        this.$emit("next-step");

        return false;
      }
      else {
        this.ShowBookTheSurgery1 = true;
      }
    },
    AddAttachmnet() {
      this.documentModel.PatientMedicalHistoryID =
        this.patientModel.PatientMedicalHistory.ID;
      this.documentModel.PatientID = this.patientModel.ID;

      if (this.documentModel.DocumentTypeID == 1) {
        //scan type
        this.PatientMedicalHistoryScans.push(this.documentModel);
      } else if (this.documentModel.DocumentTypeID == 2) {
        //analysis type
        this.PatientMedicalHistoryAnalysis.push(this.documentModel);
      } else {
        //report type
        this.PatientMedicalHistoryRepors.push(this.documentModel);
      }
      this.Model.HospitalSurgeryRequiredDocuments.forEach((element) => {
        if (this.documentModel.DocumentTypeID == 1 && element.ScanTypeID > 0) {
          //scan
          element.Checked = true;
        } else if (
          this.documentModel.DocumentTypeID == 2 &&
          element.AnalysisTypeID > 0
        ) {
          element.Checked = true;
        } else if (
          this.documentModel.DocumentTypeID == 3 &&
          element.ReportTypeID > 0
        ) {
          element.Checked = true;
        }
      });
      this.documentModel = {};
      this.ShowDocumentUpload = false;
      this.ShowBookTheSurgery1 = true;
    },
    AddPatientMedicalReportRequest() {
      debugger;
      var PatientRequestModel = {};
      PatientRequestModel.PatientReservationSurgeryID =
        this.$route.params.PatientReservationSurgeryID;
      PatientRequestModel.DocumentID = this.addItemModel.DocumentID;
      PatientRequestModel.AnalysisTypeID = this.addItemModel.AnalysisTypeID;
      PatientRequestModel.ReportTypeID = this.addItemModel.ReportTypeID;
      PatientRequestModel.ScanTypeID = this.addItemModel.ScanTypeID;
      PatientRequestModel.Description = this.addItemModel.Description;
      this.addItemModel = {};
      PatientRequestModel.StausID = 1;
      this.$vs.loading();
      this.$store
        .dispatch(
          "patientList/AddPatientMedicalReportRequest",
          PatientRequestModel
        )
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
            window.showSuccess();
          }

          this.$vs.loading.close();
        });
      this.ShowAddSurgeryItemPopup = false;
    },
    AttachmentUploaded(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);
        this.documentModel = {};
        axios.post(this.uploadUrl, data).then((response) => {
          this.documentModel.AttachPath = response.data;
          this.successUpload(this.Item);
        });
      }
    },
    successUpload(item) {
      debugger;
      item.IsUploaded = true;
      item.AttachPath = this.documentModel.AttachPath;
      this.documentModel.PatientMedicalHistoryID =
        this.patientModel.PatientMedicalHistory.ID;
      this.documentModel.PatientID = this.patientModel.ID;
      this.documentModel.ReportTypeID = item.ReportTypeID;
      this.documentModel.AnalysisTypeID = item.AnalysisTypeID;
      this.documentModel.ScanTypeID = item.ScanTypeID;
      this.documentModel.Description =
        this.Model.Surgery.Name +
        "-" +
        item.DocumentTypeName +
        "-" +
        item.Description;
      this.documentModel.DocumentTypeID = item.DocumentTypeID;
      this.documentModel.HospitalSurgeryRequiredDocumentID = item.ID;
      if (this.documentModel.ScanTypeID > 0) {
        //scan type
        this.PatientMedicalHistoryScans.push(this.documentModel);
      } else if (this.documentModel.AnalysisTypeID > 0) {
        //analysis type
        this.PatientMedicalHistoryAnalysis.push(this.documentModel);
      } else {
        //report type
        this.PatientMedicalHistoryRepors.push(this.documentModel);
      }
    },
    RemoveDocuments(documentModel) {
      debugger;
      if (documentModel.ScanTypeID > 0) {
        //scan type
        const ItemIndex = this.PatientMedicalHistoryScans.findIndex(
          (p) => p.AttachPath == documentModel.AttachPath
        );
        this.PatientMedicalHistoryScans.splice(ItemIndex, 1);
      } else if (documentModel.AnalysisTypeID > 0) {
        //analysis type
        const ItemIndex = this.PatientMedicalHistoryAnalysis.findIndex(
          (p) => p.AttachPath == documentModel.AttachPath
        );
        this.PatientMedicalHistoryAnalysis.splice(ItemIndex, 1);
      } else {
        //report type
        const ItemIndex = this.PatientMedicalHistoryRepors.findIndex(
          (p) => p.AttachPath == documentModel.AttachPath
        );
        this.PatientMedicalHistoryRepors.splice(ItemIndex, 1);
      }
      documentModel.IsUploaded = false;
    },
    BackPopup1() {
      debugger;
      this.Model.DateFromFormated = null;
      this.Model.DateToFormated = null;
      this.Model.range = null;
      if (
        this.Model.HospitalSurgeryRequiredDocuments != null ||
        (this.Model.HospitalSurgeryRequiredDocuments != undefined &&
          this.Model.HospitalSurgeryRequiredDocuments.length > 0)
      ) {
        this.Model.HospitalSurgeryRequiredDocuments.forEach((element) => {
          element.IsUploaded = false;
          element.AttachPath = null;
        });
      }
      this.PatientMedicalHistoryScans = [];
      this.PatientMedicalHistoryAnalysis = [];
      this.PatientMedicalHistoryRepors = [];
      this.ShowBookTheSurgery1 = false;
    },
    BackProcessFeesPopup() {
      this.ShowConfirmation = false;
      this.$router.push({
        name: "BookingSummary",
        params: { Flag: 2, ID: this.surgeryModel.ID },
      });
    },
    Proceed() {
      this.surgeryModel.ID = 0;
      this.surgeryModel.PatientID = this.patientModel.ID;
      if (this.surgeryModel.PatientID == undefined) {
        this.surgeryModel.PatientID =
          this.$store.state.AppActiveUser.Patient.ID;
      }
      this.surgeryModel.HospitalSurgeryID = this.Model.ID;
      this.surgeryModel.ProcessingFees = this.Model.ProcessingFees;
      this.surgeryModel.VAT = this.Model.VAT;
      this.surgeryModel.PaymentStatusID = 1;
      this.surgeryModel.SurgeryDateFrom = this.Model.range.start;
      this.surgeryModel.SurgeryDateTo = this.Model.range.end;

      this.surgeryModel.StatusID = 1;
      this.surgeryModel.PatientMedicalHistoryScans =
        this.PatientMedicalHistoryScans;
      this.surgeryModel.PatientMedicalHistoryAnalysis =
        this.PatientMedicalHistoryAnalysis;
      this.surgeryModel.PatientMedicalHistoryRepors =
        this.PatientMedicalHistoryRepors;

      this.$vs.loading();

      this.$store
        .dispatch("patientList/AddPatientReservationSurgery", this.surgeryModel)
        .then((res) => {
          debugger;
          this.surgeryModel = res.data.Data;
          window.showSuccess(res.data.message);
          this.$vs.loading.close();
          this.ShowBookTheSurgery1 = false;
          this.ShowConfirmation = true;
          this.GetSurgery();
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError(err.response.data.message);
        });
    },

    Confirm() {
      this.ShowConfirmation = false;
      this.Showpayment = true;
    },

    ConfirmPayment() {
      this.Showpayment = false;
      debugger;
      if (
        this.PatientMedicalHistoryScans.length > 0 ||
        this.PatientMedicalHistoryAnalysis.length > 0 ||
        this.PatientMedicalHistoryRepors.length > 0
      ) {
        this.PatientMedicalHistoryScans = [];
        this.PatientMedicalHistoryAnalysis = [];
        this.PatientMedicalHistoryRepors = [];
        debugger;

        this.$router.push({
          name: "BookingSummary",
          params: { Flag: 2, ID: this.surgeryModel.ID },
        });
      } else {
        window.noDocumentsUploaded();
      }
    },
    showDownloadReports() {
      debugger;
      this.patientDoc.PatientReservationSurgeryID =
        this.$route.params.PatientReservationSurgeryID;

      this.showDownloadReportsfield = true;
      debugger;
    },
    payLater() {
      this.Showpayment = false;
      window.showSuccess();
    },
    getPatientReservationSurgery(id) {
      var model = {};
      model.PatientId = id;
      model.StatusID = 2; //confirmed
      this.$vs.loading();
      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    changeDateFormat() {
      debugger;
      this.Model.DateFromFormated = moment(this.Model.range.start).format("LL");
      this.Model.DateToFormated = moment(this.Model.range.end).format("LL");
    },
    addDays(days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    GetSurgery() {
      const ID = this.$route.params.ID;
      this.$vs.loading();
      debugger;
      this.$store
        .dispatch("patientList/GetHospitalSurgery", ID)
        .then((res) => {
          debugger;
          if (res.status == 200) {
            debugger;
            this.Model = res.data.Data;
            this.Model.StatusID;
            debugger;
            if (
              this.Model.Hospital.Rate == null ||
              this.Model.Hospital.Rate == undefined ||
              this.Model.Hospital.Rate == "NaN"
            ) {
              this.Model.Hospital.Rate = 0;
            }

            if (
              this.$acl.check("patient") &&
              this.$route.params.ViewTypeID != undefined
            ) {
              this.surgeryModel.ID =
                this.$route.params.PatientReservationSurgeryID;

              if (this.$route.params.ViewTypeID == 14) {
                this.surgeryModel = {};

                this.$router.push({
                  name: "BookingSummary",
                  params: {
                    Flag: 2,
                    ID: this.$route.params.PatientReservationSurgeryID,
                  },
                });
              } else this.Showpayment = true;
            }
          }

          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    debugger;
    this.IsDoctorRole = this.$acl.check("doctor");
    debugger;
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
        localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");

    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    debugger;
    if (
      (this.$acl.check("hospital") || this.$acl.check("hospitalgroup")) &&
      (this.$route.params.ViewTypeID == undefined ||
        this.$route.params.ViewTypeID == 0)
    ) {
      this.showAddRequest = true;
    }
    if (
      this.$acl.check("doctor") &&
      (this.$route.params.ViewTypeID == undefined ||
        this.$route.params.ViewTypeID == 0)
    ) {
      debugger;
      this.showAddRequest = true; //doctor view
    }
    debugger;
    this.GetSurgery();

    if (
      !this.$acl.check("hospital") &&
      !this.$acl.check("hospitalgroup") &&
      !this.$acl.check("doctor")
    ) {
      this.$store
        .dispatch(
          "patientList/GetPatient",
          this.$store.state.AppActiveUser.Patient.ID
        )
        .then((res) => {
          this.patientModel = res.data.Data;
          if (
            this.patientModel.PatientMedicalHistory == null ||
            this.patientModel.PatientMedicalHistory == undefined
          ) {
            this.patientModel.PatientMedicalHistory = {};
          }
        });
    }
  },
};
</script>
<style>
#doctors .vx-row {
  margin: unset;
}

.material-icons {
  font-family: "Material Icons" !important;
}

.material-icons :lang(en) {
  font-family: unset;
}

.FontHead {
  font: normal normal medium 40px/51px Futura PT;
  letter-spacing: 0px;
  color: #454a62;
}

.colorHead {
  color: #0d66d0;
  /* font: normal normal medium 54px/69px Futura PT; */
  font-family: UbuntuRegular;
  font-weight: bold;
  font-size: 1.6rem;
}

.HospitalTitle {
  font-family: UbuntuRegular;
  font-weight: bold;
  font-size: 1.1rem;
}

.HospitalInfo {
  font-family: UbuntuRegular;
  font-size: 0.95rem;
}

.mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}

.popBackGround {
  background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}

.popcard .vx-card {
  box-shadow: unset;
}

.CustBG {
  background:
    radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}

.CurrentPageStatment {
  top: 414px;
  left: 600px;
  height: 100%;
  width: 100%;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 1;
  font-family: UbuntuRegular;
  font-size: 1.3rem;
}

/*  */
.OtherPageStatment {
  top: 532px;
  left: 600px;
  height: 100%;
  width: 100%;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 0.3;
  font-family: UbuntuRegular;
  font-size: 1.3rem;
  /* font-size: 2.5vh; */
}

.custHeadSurgery {
  font-weight: bold;
  font-size: 1.7rem;
  color: #2753D9;
}

.sizeCust {
  font-size: 1rem;
}

#treatP .vx-card {
  padding: 0px !important;
}

#treatP .vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 0 !important;
}

.docCardRow>.docCardCol {
  padding: 0;
}

.sr-only[data-v-fde73a0c] {
  left: 0px !important;
}

.sr-only {
  left: 0px !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>

