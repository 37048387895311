<template>
    <div>
        <b-row class="mt-1 px-2">
                <h1 class="colorHead text-4xl mt-0 px-2">
                    <p> {{ "your reservation surgery name is: " }}{{ Model.Surgery.Name }}</p>
                </h1>
            </b-row>

        <b-row>
            <b-col cols="12" md="3">
                    <imageLazy :imageStyle="{
                        'border-radius': '15px',
                        height: '250px',
                        width: '18vw',
                        'object-fit': 'cover',
                    }" :imageClass="'doctorImage'" :src="baseURL + Model.Hospital.ImagePath"
                        placeHolderType="hospital" class="mx-2"/>
                </b-col>
            <b-col cols="12" md="9">
                <div class="pt-2 px-2">
                    <div class="mt-9">
                        <p style="
                          text-align: initial;
                          font: normal normal 200 20px/21px UbuntuRegular;
                          letter-spacing: 0px;
                          color: #000000;">
                            {{ $t("medicalReportsHospitalHeading") }}
                        </p>
                    </div>

                    <div class="m-9">
                        <p v-if="Model.ProcessingFees" style="
                          text-align: center;
                          font: normal normal 300 20px/21px UbuntuRegular;
                          letter-spacing: 0px;
                          color: #000000;
                        ">
                            {{ $t("deposit") }}:
                            {{
                                    (Model.ProcessingFees + Model.VAT)
                                        .toFixed(2)
                                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                            }}{{ " $" }}
                        </p>
                    </div>
                    <div class="mt-10">
                        <p v-if="Model.DefaultHospitalPriceInUSD" style="
                          text-align: center;
                          font: normal normal 200 20px/21px UbuntuRegular;
                          letter-spacing: 0px;
                          color: #000000;
                        ">
                            {{ $t("RemainingAmountPaid") }}:
                            {{
                                    (
                                        Model.DefaultHospitalPriceInUSD -
                                        (Model.ProcessingFees + Model.VAT)
                                    )
                                        .toFixed(2)
                                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                            }}{{ " $" }}
                        </p>
                    </div>
                    <div class=" justify-center-center mb-5 mt-5 d-flex justify-content-center">

                        <b-row>
                            <b-col>
                                <vs-button class="w-full sm:w-auto text-center-center" color="primary" type="filled"
                                    @click="PayNow">
                                    {{ $t("Confirm") }}
                                </vs-button>
                            </b-col>

                            <b-col>
                                <vs-button type="line" color="black" @click="BackProcessFeesPopup()"
                                    class="  sm:mb-auto">{{ $t("Close") }}
                                </vs-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="text-center mb-5">
                    </div>
                </div>

            </b-col>
        </b-row>
    </div>
</template>


<script>
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";

import {
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BImg,
    BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";

export default {
    components: {
        BImg,
        BForm,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        imageLazy,

    },
    props: {
        Model: {
            type: Object,
            default: () => { },
        },
        surgeryModel: {
            type: Object,
            default: () => { },
        },
        patientModel: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            baseURL: domain,
            ShowConfirmation: false,
            Showpayment: false,

        };
    },
    methods: {
        changeFormatedDate(date) {
            return moment(date).format("LL");
        },

        backConfirmation() {
            this.ShowConfirmation = false;
            window.showAddSuccess();
        },
        PayNow() {
            debugger;
            this.$vs.loading();
            this.surgeryModel.FrontUrl = window.location.origin;
            this.$store
                .dispatch("patientList/ProcessingPaymentDatail", this.surgeryModel)
                .then((res) => {
                    debugger;
                    window.location.href = res.data.Data.transaction.url;
                    this.ShowConfirmation = false;
                });
        },
        BackProcessFeesPopup() {
            debugger;
            this.ShowConfirmation = false;
            this.$router.push({
                name: "BookingSummary",
                params: { Flag: 2, ID: this.surgeryModel.ID },
            });
        },
        Confirm() {
            this.ShowConfirmation = false;
            this.Showpayment = true;
        },

        ConfirmPayment() {
            this.Showpayment = false;
            debugger;
            if (
                this.PatientMedicalHistoryScans.length > 0 ||
                this.PatientMedicalHistoryAnalysis.length > 0 ||
                this.PatientMedicalHistoryRepors.length > 0
            ) {
                this.PatientMedicalHistoryScans = [];
                this.PatientMedicalHistoryAnalysis = [];
                this.PatientMedicalHistoryRepors = [];
                debugger;

                this.$router.push({
                    name: "BookingSummary",
                    params: { Flag: 2, ID: this.surgeryModel.ID },
                });
            } else {
                window.noDocumentsUploaded();
            }
        },

    },

    computed: {

    },
};
</script>