<template>
      <b-row class="m-1">
        <p class="circle   m-1 greenBackColor"  v-if="!data.icon" />
        <img alt="" v-else :src="require(`@/assets/images/travel-icon/${data.icon}`)"/>
        <p class=" fontMedium " style="width:80%;" v-if="data.title" >{{data.title}}
        
        </p>
        <p class="fontMedium" style="width:80%;" v-if="data.Name"  >{{data.Name}}</p>
         <p class=" fontMedium" style="width:80%;" v-if="data.Description"  >{{data.Description}}</p>
          <div  v-if="haveRemove" style="border-radius:50px;width: 20px; height: 20px;curser:pointer" class="primaryBackColor p-1">
                <feather-icon @click="$emit('remove')" style="color: white;width: 15px;height: 15px;" icon="XIcon"></feather-icon>
          </div>
         <div class="editButton primaryBackColor" v-if="hameEdit" @click="$emit('editTreatment')">
            {{$t("Details")}}
         </div>
        </b-row>
</template>
<script>
import {
    
    BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardText,
  BCol,
  BImg,
  BLink,
  BRow,
  BFormCheckbox
  } from "bootstrap-vue";
export default {
  components:{
    
    BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow,
BFormCheckbox},
    props: {
      data: {
        type: Object,
        default: () => {}
      },
      haveRemove: {
        type: Boolean,
        default: false
      },
       hameEdit: {
        type: Boolean,
        default: false
      }
  },
}
</script>

<style>
  .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%
}
.editButton {
  color: white;
  padding: 5px;
  border-radius: 5px;
}

</style>